<template>
  <v-card class="main pa-4">
    <div class="query">
      <v-btn color="primary" class="ml-2" @click="add">分配资产</v-btn>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="dataList"
        :loading="loading"
        hide-default-footer
        :items-per-page.sync="options.itemsPerPage"
        :page.sync="options.page"
      >
        <template v-slot:item.no="{ item, index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{
            item.updated_at.length > 19
              ? item.updated_at.substring(0, 19).replace("T", " ")
              : item.updated_at
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteItem(item.id)" color="red">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <pagination :input="{ total: total }" @output="output" />
    </div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h7 lighten-2"> 是否删除此资产? </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="del"> 确认</v-btn>
          <v-btn color="primary" text @click="dialog = false">取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Pagination from "../../components/elements/XPagination.vue";
export default {
  components: { Pagination },
  name: "CaseListPage",
  data() {
    return {
      headers: [
        {
          text: "序号",
          align: "start",
          sortable: false,
          value: "no",
        },
        { text: "资产编号", value: "identifier" },
        // { text: "域名", value: "domain" },
        { text: "资产名称", value: "assets_name" },
        { text: "所有人", value: "user_name" },
        { text: "更新时间", value: "updated_at" },
        { text: "操作", value: "actions", sortable: false },
      ],
      handleId: 0,
      dataList: [],
      dialog: false,
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: this.$store.getters.pageSize,
      },
      query: {
        no: "",
        ipv4: "",
      },
    };
  },
  methods: {
    output(data) {
      this.options = data;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios
        .get(
          `/trunkserver/assets/user/?page=${this.options.page}&&size=${this.options.itemsPerPage}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.results && res.data.results.length > 0) {
            this.dataList = res.data.results;
            this.total = res.data.count;
          } else {
            this.dataList = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    editItem(id) {},
    add() {
      this.$router.push({
        path: "/organ/allocate_add",
      });
    },
    deleteItem(id) {
      this.handleId = id;
      this.dialog = true;
    },
    del() {
      this.$axios
        .delete(`/trunkserver/assets/entity/${this.handleId}/`)
        .then((res) => {
          console.log(res);
          if (res.status > 199) {
            this.$message.success("此资产信息已删除！");
            this.dialog = false;
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleQuery() {
      this.$message.success("查询成功!");
    },
  },
  created() {
    this.getList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.query {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
</style>
