var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"main pa-4"},[_c('div',{staticClass:"query"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.add}},[_vm._v("分配资产")])],1),_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.options.itemsPerPage,"page":_vm.options.page},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.options, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.options, "page", $event)}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at.length > 19 ? item.updated_at.substring(0, 19).replace("T", " ") : item.updated_at)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('pagination',{attrs:{"input":{ total: _vm.total }},on:{"output":_vm.output}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h7 lighten-2"},[_vm._v(" 是否删除此资产? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.del}},[_vm._v(" 确认")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("取消 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }